import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "dispatchx frame.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
      <div className="hero-applications">
            <div className="container">
              <div className='column'>
                <div>
                <div className="main-text"> Dispatch <span>X.</span></div>
                <div className="sub-text">  De ideale hulp voor chauffeurs.   </div>
                </div>
                <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                <div className='child'>
                  <h3> Altijd optimaal. </h3>
                  <p>
                    Onze routes passen zich dynamisch aan aan de keuzes van de chauffeurs, verwerken alle stads- en
                    voertuig-restricties en zijn volledig geoptimaliseerd voor het specifieke voertuig.
                  </p>
                </div>
                <div className='child right'>
                  <h3> Moeiteloze samenwerking.</h3>
                  <p>
                    Communiceer gemakkelijk met de klant en met de administratie. Stond een container niet klaar, sta je in de file 
                    of is er een ander probleem? Geef het via de app moeiteloos door aan de betreffende partij. 
                  </p>
                </div>
                <div className='child'>
                  <h3> Alle informatie bij de hand. </h3>
                  <p>
                  Noteer bij iedere ophaallocatie alle relevante informatie: wat is er opgehaald, hoeveel, maak foto’s en scan 
                  containers indien deze uitgerust zijn met sensoren. Alle data gaat direct door naar de algoritmen voor een analyse.
                  </p>
                </div>
            </div>
          </div>
        </div>
    )
}

export default Hero

