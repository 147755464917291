import React from "react"
import { FaInfo, FaRegCommentDots, FaRoute} from "react-icons/fa"

const Features = () => {
    return(
        <div className="features-applications">
        <div className="container">
        <div className="row-feat header">
                <div className="column-feat header">
                <div className="main-text"> Functionaliteiten </div>
                <div className="sub-text">  Kies uit onze geavanceerde modules om de applicatie volledig te personaliseren naar 
                jouw proces.  </div>
                </div>
            </div>
            <div className="row-feat">
                <div className="column-feat">
                    <div className="title">Routes.</div>
                    <div> <FaRoute size={50} color={"#7FACD6"}/> </div>
                    <div>De optimale route altijd bij de hand.</div>
                    <div>Volg de voortgang op de kaart en krijg inzichtelijk in waar je nog heen moet. </div>
                    <div>Inzicht in tijdsvensters en last-minute route wijzigingen. </div>
                </div>
                <div className="column-feat">
                    <div className="title">Communicatie.</div>
                    <div> <FaRegCommentDots size={50} color={"#7FACD6"}/> </div>
                    <div>Klant niet aanwezig of verkeerd afval? Maak direct een melding naar de administratie.  </div>
                    <div>Last-minute veranderingen op de dag worden automatisch doorgevoerd in de routes.  </div>
                    <div>Communiceer met andere chauffeurs.</div>
                </div>
                <div className="column-feat">
                    <div className="title">Informatie.</div>
                    <div> <FaInfo size={50} color={"#7FACD6"}/> </div>
                    <div>Vink bezochte locaties af en noteer informatie over het opgehaald afval. </div>
                    <div>Scan containers of maak een foto voor snel handelen. </div>
                    <div>Noteer problemen onderweg.</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Features